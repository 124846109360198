import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class KnoopApiService {
    private body = {
        action: '',
        id: '',
        api_key: 'API1',
        auth_token: 'nokey',
        version: '11',
        type: 'public',
        route_id: '',
        user_id: '0',
        sender: 'pwa',
    };

    private urlApi = 'https://www.knooppunter.com/api/index.php?action=';
    private routeDetailsAction = 'getAppRoute/';
    private logAction = 'log';

    constructor() {
        const domain = window.location.host;
//        console.log('test domain'+domain);
        switch(domain) {
            case "appknp.webnuts.eu":
                this.urlApi = 'https://knooppunter2022.webnuts.eu/ws/knp_route/';
                break;
            case "appknp.dev.lannoodcs.com":
                this.urlApi = 'https://knooppunter.dev.lannoodcs.com/ws/knp_route/';
                break;
            case "appknp.staging.lannoodcs.com":
                this.urlApi = 'https://knooppunter.staging.lannoodcs.com/ws/knp_route/';
                break;
            case "appknp.knooppunter.com":
                this.urlApi = 'https://www.knooppunter.com/ws/knp_route/';
                break;
            case "appknp.lepointnoeud.com":
                this.urlApi = 'https://www.lepointnoeud.com/ws/knp_route/';
                break;
    

        }
       // console.log(domain);

    }

    public async getRouteDetails(routeId: any) {


        let options = {method: 'get'};
        //let body = this.body;
        //body.route_id = routeId;
        //options.body = JSON.stringify(this.body);


        const webResp = await fetch(this.urlApi + this.routeDetailsAction + routeId, options);
        const data = await webResp.json();

        return data;

    }

    public async sendKppAction(kppId: any, routeId: any, userId: any) {
        const options = {body: '', method: 'post'};
        let body = this.body;
        body.route_id = routeId;
        body.action = 'app.route.kpp';
        body.id = kppId;
        body.user_id = userId;
        options.body = JSON.stringify(this.body);



        const webResp = await fetch(this.urlApi + this.logAction, options);
        const data = await webResp.json();

        return data;
    }

    public async sendStartAction( routeId: any, userId: any) {
        const options = {body: '', method: 'post'};
        let body = this.body;
        body.route_id = routeId;
        body.action = 'app.route.start';
        body.id = routeId;
        if (userId !== null && userId !== undefined){
            body.user_id = userId;
        }
        options.body = JSON.stringify(this.body);

        const webResp = await fetch(this.urlApi + this.logAction, options);
        const data = await webResp.json();

        return data;
    }

} 
