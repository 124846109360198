import {Component} from '@angular/core';

import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {GlobalBagService} from './services/global-bag.service';
import {KnoopApiService} from './services/knoop-api.service';
import {Router} from '@angular/router';
import {Insomnia} from '@ionic-native/insomnia/ngx';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    isMenuWide = false;
    endLink: any;
    favIcon: HTMLLinkElement = document.querySelector('#appIcon');

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public globalParams: GlobalBagService,
        private router: Router,
        private insomnia: Insomnia
    ) {
        const url = new URL(window.location.toString());
        const routeId = url.searchParams.get('route');
        const uid = url.searchParams.get('refu');
        const s = url.searchParams.get('s');
        console.log('routeid'+routeId);
        this.globalParams.setDomain();
        this.globalParams.setParam('routeId', routeId);
        this.globalParams.setParam('uid', uid);
        this.globalParams.setParam('s', s);
        let language = url.searchParams.get('lg');
        if (language === null) {
            language = this.globalParams.getConfig(this.globalParams.config.defaultLanguage);
        }
        this.globalParams.setParam('language', language);
        const domain = url.searchParams.get('dom');
        this.globalParams.setDomain(domain);
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.changeIcon();
            this.statusBar.styleDefault();
            this.splashScreen.hide();
            this.globalParams.setScheme();
            this.endLink = this.globalParams.getHomeLink();
            const scheme = this.globalParams.getParam('scheme');
            const domain = this.globalParams.getParam('cssDomain');

            document.title = this.globalParams.t(this.globalParams.lang.title);

            document.querySelector('body').classList.add(domain);
            let route = this.globalParams.getParam('routeId');
            if (!this.globalParams.checkS()) {
                route = 1;
                this.globalParams.setParam('routeId', route);
                console.log('sign Error');
            }

            if (route === null) {
                this.router.navigate(['map']);
            } else {
                this.router.navigate(['map']);
            }
            this.insomnia.keepAwake()
                .then(
                    () => console.log('keepawake success'),
                    () => console.log('keepawake error')
                );
        });
    }

    onMenuClick() {
        if (this.isMenuWide === true) {
            this.isMenuWide = false;
        } else {
            this.isMenuWide = true;
        }
    }

    changeIcon() {
        this.favIcon.href = this.globalParams.getConfig(this.globalParams.config.favIconPath);
    }

}
